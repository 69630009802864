<template>
  <template v-if="tabs.length > 0">
    <div class="user-info" v-if="userName">用户 {{userName}}({{userPhone}})的订单列表</div>
    <div class="screen-list">
      <div class="screen-time">
        <span @click="showPopup">{{startCreateTime ? '开始时间 ：' + startCreateTime : '请选择开始时间'}}</span>
        <span @click="showPopupend">{{endCreateTime ? '结束时间 ：' + endCreateTime : '请选择结束时间'}}</span>
        <van-popup v-model:show="show" position="bottom">
          <van-datetime-picker
            v-model="startCreateTime"
            type="date"
            title="选择时间"
            :loading="isLoadingShow"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="show = false"
            @confirm="confirmPicker"
          />
        </van-popup>
        <van-popup v-model:show="showend" position="bottom">
          <van-datetime-picker
            v-model="endCreateTime"
            type="date"
            title="选择时间"
            :loading="isLoadingShowend"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="showend = false"
            @confirm="confirmPickerend"
          />
        </van-popup>
      </div>
      <div>
        <van-field
          v-model="buyerPhone"
          label-width="8em"
          input-align="left"
          label="用户手机号"
          placeholder="请输入手机号查询"
        />
      </div>
      <div style="display: flex;align-items: center;">
        <van-button type="primary" block @click="search">搜索订单</van-button>
      </div>
      <div class="screen-tongji">
        <div>
          <p>{{totalElements || 0}}</p>
          <span>订单总数</span>
        </div>
        <div>
          <p>{{totalAmount || 0}}</p>
          <span>普通订单总额</span>
        </div>
        <div>
          <p>{{freeAmount || 0}}</p>
          <span>自由付总额</span>
        </div>
      </div>
    </div>
    <van-tabs v-model:active="active" :before-change="beforeChange">
      <van-tab v-for="(tab, index) in tabs" :title="tab.v" :key="index">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getData"
        >
          <div v-for="item in list" :key="item.orderId" style="margin:15px 0 0 0;">
            <van-cell :title="item.store.name" />
            <van-card
              :num="item.productQuantity"
              :price="item.productPrice"
              :desc="item.productSkuText"
              :title="item.productName"
              :thumb="item.productImage"
            >
              <template #tags v-if="item.targetDate">
                <van-tag plain type="danger">{{ item.bookingDate }}</van-tag>
              </template>
            </van-card>
            <div style="line-height:1.6;padding:15px;background:#fff">
              <p>
                <small>时间: {{ item.createTime }}</small>
              </p>
              <p>
                <small>实收: {{ item.payAmount }}</small>
              </p>
              <template v-if="item.grouponNumber !== ''">
                <van-tag plain type="primary">团购订单</van-tag>&nbsp;
                <van-tag plain type="primary">{{ convertGrouponStatus(item.groupon.status) }}</van-tag>&nbsp;
                <van-tag plain type="primary">
                  {{ item.groupon.minParticipants }}人团
                  <template v-if="item.groupon.minParticipants > item.groupon.currentParticipants">
                    ，还差{{ item.groupon.minParticipants - item.groupon.currentParticipants }}人
                  </template>
                </van-tag>
              </template>
            </div>
            <div style="background:#fff;padding:15px;text-align:right;">
              <van-button size="small" :to="{ name: 'OrderDetail', query: { orderId: item.orderId } }">订单详情</van-button>
              <!-- <template v-if="item.status === 'CONFIRMED'">
                <template v-if="item.biz === 'TRANSPORT'">
                  &nbsp;<van-button size="small" :to="'/order/deliver?order_id=' + item.orderId">发货</van-button>
                </template>
              </template>
              <template v-if="item.status === 'WAIT_DELIVER'">
                &nbsp;<van-button size="small" :to="'/order/updateTrackingNumber?order_id=' + item.orderId">发货</van-button>
              </template>
              <template v-if="item.status === 'DELIVERED'">
                &nbsp;<van-button size="small" :to="'/order/updateTrackingNumber?order_id=' + item.orderId">修改运单号</van-button>
              </template>
              <template v-if="item.status === 'REFUNDING'">
                &nbsp;<van-button size="small" :to="'/order/reviewRefund?order_id=' + item.orderId">审核退款</van-button>
              </template> -->
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { listOrderStatus, convertGrouponStatusText, convertOrderStatusText } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      show: false,
      showend: false,
      minDate: new Date(2016, 12, 31),
      maxDate: new Date(),
      isLoadingShow: true,
      isLoadingShowend: true,
      endCreateTime: '',
      startCreateTime: '',
      buyerPhone: '',
      totalElements: '',
      totalAmount: '',
      list: [],
      active: useRoute.query.tab ? parseInt(useRoute.query.tab) : 0,
      tabs: [],
      storeId: Cookies.get('storeId'),
      userPhone: useRoute.query.userPhone ? useRoute.query.userPhone : null,
      userName: useRoute.query.userName ? useRoute.query.userName : null,
      freeAmount: 0
    })
    const convertGrouponStatus = (value) => {
      return convertGrouponStatusText({ status: value })
    }
    const convertOrderStatus = (status, biz) => {
      return convertOrderStatusText({ status: status, biz: biz })
    }
    const init = () => {
      state.tabs = listOrderStatus()
      post('/order.sumOrder', {
        storeId: state.storeId,
        bizScope: 'FREE_PAY',
        status: 'COMPLETED'
      }).then(res => {
        state.freeAmount = res.data.payAmount
      })
      // getData()
    }
    const getData = (type) => {
      var pageParams = {
        pageNum: state.page,
        status: state.tabs[state.active].k,
        bizScope: 'GENERAL',
        storeId: state.storeId
      }
      if (state.userPhone) {
        pageParams.buyerUserPhone = state.userPhone
      }
      if (type === 'search') {
        pageParams.pageNum = 1
        if (state.startCreateTime) {
          pageParams.startCreateTime = state.startCreateTime + ' 00:00:00'
        }
        if (state.endCreateTime) {
          pageParams.endCreateTime = state.endCreateTime + ' 23:59:59'
        }
        if (state.buyerPhone) {
          pageParams.buyerPhone = state.buyerPhone
        }
      }
      post('/order.list', pageParams).then(res => {
        if (pageParams.pageNum === 1) {
          if (res.data.content.length) {
            state.list = res.data.content
          } else {
            state.list = []
          }
        } else {
          state.list = [...state.list, ...res.data.content]
        }
        state.totalElements = res.data.totalElements
        state.totalAmount = res.data.totalAmount
        state.loading = false
        state.finished = res.data.last
        state.page++
      })
    }
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const showPopup = () => {
      state.show = true
      state.isLoadingShow = true
      setTimeout(() => {
        state.isLoadingShow = false
      }, 500)
    }
    const showPopupend = () => {
      state.showend = true
      state.isLoadingShowend = true
      setTimeout(() => {
        state.isLoadingShowend = false
      }, 500)
    }
    const confirmPicker = (val) => {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      state.show = false
      state.startCreateTime = year + '-' + month + '-' + day
    }
    const confirmPickerend = (val) => {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      const timeValueend = `${year}-${month}-${day}`
      state.showend = false
      state.endCreateTime = timeValueend
    }
    const beforeChange = (index) => {
      state.active = index
      reset()
      return true
    }
    const search = () => {
      getData('search')
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      search,
      showPopup,
      showPopupend,
      confirmPicker,
      confirmPickerend,
      beforeChange,
      convertGrouponStatus,
      convertOrderStatus
    }
  }
}
</script>

<style scoped>
  .screen-list .screen-time{
    display: flex;
    padding: 16px;
    background-color: #fff;
    font-size: 14px;
  }
  .screen-list .screen-time span{
    width: 50%;
    display: block;
    text-align: left;
  }
  .screen-list .screen-time i{
    width: 10%;
    display: block;
  }
  .screen-tongji{
    display: flex;
    padding: 18px 0px;
    background-color: #fff;
  }
  .screen-tongji div{
    text-align: center;
    width: 33.333%;
    font-size: 13px;
  }
  .screen-tongji div p{
    font-size: 16px;
    margin-bottom: 3px;
  }
  .user-info{
    line-height: 35px;
    background-color: #fff;
    padding-left: 15px;
    font-weight: bold;
  }
</style>
